<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">PERFILES DE PUESTOS DE TRABAJO REGISTRADO EN EL SISTEMA:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-users-cog fa-3x"></i>
                            <br>
                            <span class="lg-numero">{{listaPuestos.length}}</span>
                            <br>
                            <span class="text-muted">Puestos de trabajo</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de perfiles de puestos de trabajo</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block :to="{name: 'Recursos humanos'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Volver
                            </b-button>
                        </b-col>
                        <b-col  v-if="checkPermissions('009-REC-REC-PUE','c')==1" cols="6" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarPuesto = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo puesto
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Puestos de trabajo registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaPuestos" :fields="campoPuestos" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button  v-if="checkPermissions('009-REC-REC-PUE','u')==1" @click="abrirModalActualizarPuesto(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button  v-if="checkPermissions('009-REC-REC-PUE','d')==1" @click="eliminarPuesto(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalRegistrarPuesto">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo puesto </span>
            </h6>
            <CButtonClose @click="modalRegistrarPuesto = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarPuesto)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="puesto de trabajo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Puesto de trabajo:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el puesto de trabajo" v-model="datosPuestoTrabajo.puestoTrabajo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="formación básica" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Formación básica:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la formación básica" v-model="datosPuestoTrabajo.formacionBasica"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="misión del puesto" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Misión del puesto:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la misión del puesto" v-model="datosPuestoTrabajo.misionPuesto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="especialización" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Especialización:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la especialización" v-model="datosPuestoTrabajo.especializacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="funciones" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Funciones:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" size="md" :state="getValidationState(validationContext)" placeholder="Ingrese las funciones" v-model="datosPuestoTrabajo.funciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="idioma" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Idioma:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el idioma" v-model="datosPuestoTrabajo.idioma"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de actualizacion" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de actualizacion:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" size="md" v-model="datosPuestoTrabajo.fechaActualizacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="manejo de software" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Manejo de software:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el manejo de software" v-model="datosPuestoTrabajo.manejoSoftware"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="observaciones" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" size="md" :state="getValidationState(validationContext)" placeholder="Ingrese las observaciones" v-model="datosPuestoTrabajo.observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="experiencia previa mínima" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Experiencia previa mínima:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la experiencia previa" v-model="datosPuestoTrabajo.experienciaPreviaMinima"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarPuesto = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalActualizarPuesto">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar puesto</span></h6>
            <CButtonClose @click="modalActualizarPuesto = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarPuesto)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="puesto de trabajo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Puesto de trabajo:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el puesto de trabajo" v-model="datosActualizarPuestoTrabajo.puestoTrabajo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="formación básica" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Formación básica:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la formación básica" v-model="datosActualizarPuestoTrabajo.formacionBasica"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="misión del puesto" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Misión del puesto:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la misión del puesto" v-model="datosActualizarPuestoTrabajo.misionPuesto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="especialización" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Especialización:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la especialización" v-model="datosActualizarPuestoTrabajo.especializacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="funciones" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Funciones:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" size="md" :state="getValidationState(validationContext)" placeholder="Ingrese las funciones" v-model="datosActualizarPuestoTrabajo.funciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="idioma" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Idioma:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el idioma" v-model="datosActualizarPuestoTrabajo.idioma"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha de actualizacion" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de actualizacion:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarPuestoTrabajo.fechaActualizacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="manejo de software" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Manejo de software:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el manejo de software" v-model="datosActualizarPuestoTrabajo.manejoSoftware"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="observaciones" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" size="md" :state="getValidationState(validationContext)" placeholder="Ingrese las observaciones" v-model="datosActualizarPuestoTrabajo.observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="experiencia previa mínima" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Experiencia previa mínima:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la experiencia previa" v-model="datosActualizarPuestoTrabajo.experienciaPreviaMinima"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarPuesto = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalRegistrarPuesto: false,
            modalActualizarPuesto: false,
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoPuestos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "puestoTrabajo",
                    label: "Puesto de trabajo",
                    class: "text-center",
                },
                {
                    key: "fechaActualizacion",
                    label: "Fecha de actualización",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaPuestos: [],

            datosPuestoTrabajo: {
                puestotrabajo: '',
                misionPuesto: '',
                funciones: '',
                fechaActualizacion: moment().format('YYYY-MM-DD'),
                observaciones: '',
                formacionBasica: '',
                especializacion: '',
                idioma: '',
                manejoSoftware: '',
                experienciaPreviaMinima: ''
            },
            datosActualizarPuestoTrabajo: {
                idPuestoTrabajo: '',
                puestotrabajo: '',
                misionPuesto: '',
                funciones: '',
                fechaActualizacion: null,
                observaciones: '',
                formacionBasica: '',
                especializacion: '',
                idioma: '',
                manejoSoftware: '',
                experienciaPreviaMinima: ''
            },
            disabled: false,
            datosSession: {
                idCliente: ''
            },
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaPuestos = response.data;
                    me.totalRows = me.listaPuestos.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarPuesto() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-puesto-trabajo", {
                        datosPuestoTrabajo: me.datosPuestoTrabajo,
                        idCliente: me.datosSession.idCliente
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarPuestosTrabajo();
                    me.modalRegistrarPuesto = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarPuesto() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-puesto-trabajo", {
                        datosPuestoTrabajo: me.datosActualizarPuestoTrabajo,
                        idCliente: me.datosSession.idCliente
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarPuestosTrabajo();
                    me.modalActualizarPuesto = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        abrirModalActualizarPuesto(param) {
            let me = this;
            me.datosActualizarPuestoTrabajo.idPuestoTrabajo = param.item.idPuestoTrabajo;
            me.datosActualizarPuestoTrabajo.puestoTrabajo = param.item.puestoTrabajo;
            me.datosActualizarPuestoTrabajo.formacionBasica = param.item.formacionBasica;
            me.datosActualizarPuestoTrabajo.misionPuesto = param.item.misionPuesto;
            me.datosActualizarPuestoTrabajo.especializacion = param.item.especializacion;
            me.datosActualizarPuestoTrabajo.funciones = param.item.funciones;
            me.datosActualizarPuestoTrabajo.idioma = param.item.idioma;
            me.datosActualizarPuestoTrabajo.fechaActualizacion = param.item.fechaActualizacion;
            me.datosActualizarPuestoTrabajo.manejoSoftware = param.item.manejoSoftware;
            me.datosActualizarPuestoTrabajo.observaciones = param.item.observaciones;
            me.datosActualizarPuestoTrabajo.experienciaPreviaMinima = param.item.experienciaPreviaMinima;

            me.modalActualizarPuesto = true;
        },
        resetModalRegistrarPuesto() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosPuestoTrabajo.puestoTrabajo = '';
            this.datosPuestoTrabajo.misionPuesto = '';
            this.datosPuestoTrabajo.funciones = '';
            this.datosPuestoTrabajo.fechaActualizacion = moment().format('YYYY-MM-DD');
            this.datosPuestoTrabajo.observaciones = '';
            this.datosPuestoTrabajo.formacionBasica = '';
            this.datosPuestoTrabajo.especializacion = '';
            this.datosPuestoTrabajo.idioma = '';
            this.datosPuestoTrabajo.manejoSoftware = '';
            this.datosPuestoTrabajo.experienciaPreviaMinima = '';
        },
        resetModalActualizarPuesto() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        eliminarPuesto(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el puesto de trabajo?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-puesto-trabajo", {
                            idPuestoTrabajo: param.item.idPuestoTrabajo,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarPuestosTrabajo();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalRegistrarPuesto: function (val) {
            if (val == false) {
                this.resetModalRegistrarPuesto();
            }
        },
        modalActualizarPuesto: function (val) {
            if (val == false) {
                this.resetModalActualizarPuesto();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarPuestosTrabajo();
        }
    }

}
</script>
